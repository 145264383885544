.booking-form {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .table-section, .details-section {
    margin-bottom: 30px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  th, td {
    padding: 3px 5px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  th {
    background-color: #f5f5f5;
  }
  
  tr:hover {
    background-color: #f0f0f0;
    cursor: pointer;
  }
  
  tr.selected {
    background-color: #e0e0e0;
  }
  
  .pagination {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
  
  .pagination button {
    padding: 5px 10px;
  }
  
  .pagination button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .details-section {
    border: 1px solid #ddd;
    padding: 20px;
    background-color: #fff;
  }
  
  .details-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .details-grid div {
    display: flex;
    flex-direction: column;
  }
  
  label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input {
    padding: 8px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
  }
  
  .action-buttons {
    display: flex;
    gap: 10px;
  }
  
  button {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }

  .search-section {
    margin-bottom: 20px;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 4px;
  }
  
  .ant-form-item {
    margin-right: 10px;
  }