
.formulario {
    margin: 10rem 5rem;    
    text-align: center;
}
.formulario h2 {
    margin: 30px;
    text-align: center;
}
 
.header{
    display: inline;
    padding-left: 65%;
    font-size: 20;
    font-weight: bold;
    text-transform: uppercase;
}
@media (min-width: 768px){ 
    .header{
        display: inline;
        padding-left: 1.5rem;
        font-size: 30;
    }
} 
.carrusel{
    margin-top: 3px;
    width: 100%;
} 
.tab {
    margin-left: 20px;
    width: 100%;
}
.formulario_div {
    width: min(100%, 1100px); 
}
.tabla {
    display: none;    
}

@media (min-width: 768px){

    .tabla{
        padding-left: auto;
        padding-top: 0.5rem; 
        display: block;    
    }

    th, td {
        margin: 0;
        padding: 0;
    }
     
}
.icono {
    font-size: 1.2rem;
    padding-top: 0.5rem;
}
.oculto {
    display: none;
}
.login {   
    display: flex; 
    justify-content: center; 
    align-items: center; 
}
@media(min-width: 768px) { 
    .login {
        width: 80%;
        height: 70%;
        margin: 1%  auto 0 auto;           
    }
}
.contenedor { 
    max-width: 140rem;
    margin: 0 auto;
    width: 95%;
}
.logo { 
    margin: 1.2rem; 
    padding: 1rem; 
    display: block;
    justify-content: center;
    align-items: center; 
}
.btn  { 
    padding: 1rem 0 0 0;
}
@media (min-width: 768px){
    .btn{ 
        padding-top: 0; 
    }
}
.contenedor_login {
    height: 16rem;  
    width: 100%;
    background-image: url(./components/ui/img/fondo.jpg);       
    background-size: cover;
    background-repeat: no-repeat; 
    background-position: center center;  
    margin: 0rem;
}
@media (min-width: 768px){
    .contenedor_login{ 
        height: 100vh;  
        display: flex;
        background-position: center left;  
    }
}
.hero {
    padding-top: 18rem;     
}
@media (min-width: 768px){
    .hero{  
        width: 32rem;
        height: 94%;
        margin: 1.2rem 1.2rem; 
        padding-top: 1rem;
        position: relative;
        border-radius: .8rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; 
        background-color: rgba(255, 255, 255, 0.4); 
    }
}
.year {
    color: #1D2758;
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
}
@media (min-width: 768px){
    .year {
        color: white;
    }
}
.footer_logo {
    width: 15rem; 
    padding: 0.5rem;    
} 
@media (min-width: 768px) { 

    .footer_logo{
        width: 12rem; 
        display: block;
        margin: 0.5rem;
    }
}
.acciones {
    display: flex;
    justify-content: flex-end; 
    margin-bottom: 1.2rem;   
}
.contenedor_btn {  
    display: flex;
    justify-content: center; 
    align-items: center; 
}
.contenedorForm {
    width: 100%; 
}
@media (min-width: 575px) { 
    .contenedorForm {
        max-width: 575px; 
    }
}
@media (min-width: 768px) { 
    .contenedorForm {
        max-width: 700px; 
    }
}
@media (min-width: 950px) { 
    .contenedorForm {
        width: 850px; 
    }
}

.ant-input::placeholder {
    color: rgba(0, 0, 0, 0.65); 
}
.registro {
    margin-bottom: 0.75rem;
}

.seccionForm { 
    width: 100%; 
    border: 0.1rem solid gray;
    padding: 1rem;
    margin: 0.5rem;
}

.inputsForm {
    display: block; 
    /* justify-content: space-between; */
}

@media (min-width: 768px) { 

    .inputsForm{
        display: flex; 
        justify-content: space-between;
    }
}

.inputsAwbSerial {
    width: 20rem;
    min-width: 14rem;
    display: flex;
    justify-content: space-between;   
}

@media (min-width: 768px) { 

    .inputsAwbSerial{
        width: 30%;
        
    }
}

.ContenedorSearchFligths {
    margin-left: 72%;
}

@media (min-width: 768px) { 
    .ContenedorSearchFligths{
        margin-left: 0.5rem;
    }
}

.back{ 
    gap: 0.5rem;
    padding: 0.8rem; 
    width: 100%;
}
@media (min-width: 768px) { 
   .back {
        display: flex;
   }  
} 

Sider {
    background-image: linear-gradient(to bottom, #060606, #464646, #848484);
}

.content{
    background-image: linear-gradient(180deg,#e8e8e8,#f8f8f8);
}

.formTrack {
    display: flex; 
    justify-content: space-between;    
} 

.contendorFlights{
    display: flex;
    justify-content: space-between;
}

.seccionFlight {
    padding: 1rem;
}

.ant-layout-sider{
    background: none; 
}
/* --------------  CUSTOM ANT FORMS FORMAT ----------------- */
.ant-form-item-explain {
    font-size: 12px; /* Adjust this value to make the font smaller*/
}
    
.ant-form-item-explain-error {
    font-size: 12px; /* Adjust this value to make the font smaller*/
    background: none;
}

.ant-table-cell {
    padding: 0.1rem !important;
    /*font-size: 12px; /* Adjust this value to make the font smaller*/
}

.right-aligned-input input {
    text-align: right;
  }

.overlay {
    position: fixed;
    top: 0;
    left: 200px; 
    width: calc(100% - 200px); 
    height: 100%; 
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 9998;
    animation: slideIn 0.27s ease-in-out forwards;
  }
  
  @keyframes slideIn { 
    from {
      left: -100%;
    }
    to {
      left: 200;
    }
  }